@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .a-default-light {
    @apply text-violet-700;
    @apply hover:bg-violet-200 hover:text-violet-900 hover:ring-violet-200;
    @apply focus:bg-violet-200 focus:text-violet-900 focus:ring-offset-violet-200 focus:ring-violet-900;
  }
  .a-default-dark {
    @apply text-violet-500;
    @apply hover:bg-violet-800 hover:text-violet-200 hover:ring-violet-800;
    @apply focus:bg-violet-800 focus:text-violet-200 focus:ring-offset-violet-800 focus:ring-violet-200;
  }
  .a-default {
    @apply transition-all rounded-sm inline-block relative origin-center bg-origin-border;
    @apply hover:ring-4;
    @apply focus:ring-offset-4 focus:ring-2 focus:outline-none;
    @apply a-default-light dark:a-default-dark;
  }
  .a-fixed-padding {
    @apply a-default px-1 -mx-1;
  }

  .menu-item-light {
    @apply text-neutral-700;
    @apply hover:bg-violet-200 hover:text-violet-900;
    @apply focus:bg-violet-200 focus:text-violet-900 focus:ring-violet-900;
  }
  .menu-item-dark {
    @apply text-neutral-300;
    @apply hover:bg-violet-800 hover:text-violet-200;
    @apply focus:bg-violet-800 focus:text-violet-200 focus:ring-violet-200;
  }
  .menu-item {
    @apply transition-all rounded-sm inline-block relative origin-center bg-origin-border px-3 py-2;
    @apply focus:ring-2 focus:outline-none;
    @apply menu-item-light dark:menu-item-dark;
  }
}
